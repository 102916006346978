import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LanguageSwitcher from "./partials/languangeSwitcher";
import { Popover } from "flowbite-react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main>
      <nav className="bg-white fixed w-full px-5 sm:px-10 md:px-20 z-20 top-0 start-0 shadow-xl shadow-gray-100">
        <div className="max-w-6xl flex flex-wrap items-center justify-between py-4 mx-auto">
          <a href="https://hulutarget.com" className="flex items-center">
            <img
              src="/assets/icon/hulutarget.png"
              className="h-16 absolute"
              alt="hulutarget Logo"
            />
            <img
              src="/assets/icon/hulu-target.png"
              className="h-12 translate-x-10"
              alt="hulutarget Logo"
            />
          </a>

          <div className="flex md:order-2 space-x-3 items-center ">
            <div className="md:flex gap-3 items-center hidden">
              <LanguageSwitcher />
              <span className="text-gray-400">|</span>
              <a
                href="https://app.hulutarget.com/login"
                type="button"
                className="text-gray-400 hover:text-primary-macaroni font-medium text-sm"
              >
                Login
              </a>
              <a
                href="https://app.hulutarget.com/register"
                type="button"
                className="text-primary-macaroni bg-[#181617] hover:bg-primary-macaroni hover:text-white focus:ring-4 focus:outline-none focus:ring-dark-tosca font-medium rounded-full px-4 py-2 text-sm text-center"
              >
                Get started for free
              </a>
            </div>
            <div className="md:hidden">
              <LanguageSwitcher />
            </div>
            <button
              onClick={toggleNavbar}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-slate-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-sticky"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              isOpen ? "block" : "hidden"
            }`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white gap-1">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `block py-2 px-4 rounded md:bg-transparent hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-macaroni md:p-0 ${
                      isActive ? "text-gray-600" : "text-gray-400"
                    }`
                  }
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>

              <li>
                <a
                  href="https://app.hulutarget.com/price"
                  className="block py-2 px-4 text-gray-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-warning md:p-0"
                >
                  Price
                </a>
              </li>
              <li>
                <Popover
                  aria-labelledby="default-popover"
                  trigger="hover"
                  className="shadow-lg border-0 bg-white rounded-xl ml-10"
                  arrow={false}
                  content={
                    <div className="w-fit text-sm">
                      <div className="p-4">
                        <NavLink
                          to="/help-center-manual-book"
                          className={({ isActive }) =>
                            `flex justify-between gap-3 py-2 px-4 mb-3 rounded md:bg-transparent hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-macaroni md:p-0 ${
                              isActive ? "text-gray-600" : "text-gray-400"
                            }`
                          }
                          aria-current="page"
                        >
                          Manual Book
                        </NavLink>
                        <NavLink
                          to="/help-center-tutorial-guide"
                          className={({ isActive }) =>
                            `flex justify-between gap-3 py-2 px-4 rounded md:bg-transparent hover:bg-gray-100 md:hover:bg-transparent md:hover:text-primary-macaroni md:p-0 ${
                              isActive ? "text-gray-600" : "text-gray-400"
                            }`
                          }
                          aria-current="page"
                        >
                          Tutorial Guide
                        </NavLink>
                      </div> 
                    </div>
                  }
                >
                  <span className={`flex gap-2 ${location.pathname.startsWith('/help-center') ? 'text-gray-600': 'text-gray-400'}`}>
                    Help
                    <ChevronDownIcon className="w-4" />
                  </span>
                </Popover>
              </li>
              <li>
                <div className="md:hidden py-2 px-4 bg-gray-300 hover:bg-gray-200 rounded">
                  <a
                    href="https://app.hulutarget.com/login"
                    type="button"
                    className="text-gray-600 hover:text-primary-macaroni font-medium text-sm"
                  >
                    Login
                  </a>
                </div>
              </li>
              <li>
                <div className="md:hidden py-2 px-4 text-primary-macaroni bg-primary-black hover:bg-primary-macaroni hover:text-white focus:ring-4 focus:outline-none focus:ring-dark-tosca font-medium rounded">
                  <a
                    href="https://app.hulutarget.com"
                    type="button"
                    className="text-sm text-center"
                  >
                    Get started for free
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </main>
  );
};
