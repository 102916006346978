// src/pages/NotFoundPage.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "flowbite-react";
import { useTranslation } from "react-i18next";
export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-semibold mb-4 text-primary-macaroni">{t("Page Not Found")}</h1>
      <p className="text-primary-black">{t("Redirecting you back in a few seconds...")}</p>
        <Spinner className="mt-4" color="warning" size="xl" />
    </div>
  );
}
