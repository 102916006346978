import React, { useEffect, useState } from "react";
import { Dropdown } from "flowbite-react";
import i18n from "../../i18n";

const LanguageSwitcher = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setCurrentLanguage(lng);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      setCurrentLanguage(storedLanguage);
    }
  }, []);

  const isSelected = (lng) => lng === currentLanguage;

  return (
    <Dropdown
      label=""
      closeInside={true}
      renderTrigger={() => (
        <img
          src={`/assets/icon/lang/${currentLanguage}.jpg`}
          alt={`${currentLanguage} Flag`}
          className="h-6 w-10 rounded cursor-pointer border hover:border-primary-macaroni hover:border-2"
        />
      )}
    >
      <Dropdown.Item
        onClick={() => changeLanguage("id")}
        className={isSelected("id") ? "bg-gray-100" : ""}
      >
        <img
          src="/assets/icon/lang/id.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        Indonesia
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => changeLanguage("en")}
        className={isSelected("en") ? "bg-gray-100" : ""}
      >
        <img
          src="/assets/icon/lang/en.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        English
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => changeLanguage("ar")}
        className={isSelected("ar") ? "bg-gray-100" : ""}
      >
        <img
          src="/assets/icon/lang/ar.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        Arabic
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => changeLanguage("th")}
        className={isSelected("th") ? "bg-gray-100" : ""}
      >
        <img
          src="/assets/icon/lang/th.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        Thailand
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => changeLanguage("cn")}
        className={isSelected("cn") ? "bg-gray-100" : ""}
      >
        <img
          src="/assets/icon/lang/cn.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        China
      </Dropdown.Item>
    </Dropdown>
  );
};

export default LanguageSwitcher;