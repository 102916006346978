import { Navbar } from "../components/navbar";
import Footer from "../components/footer";
import Jumbotron from "../components/jumbotron";
import { Accordion } from "flowbite-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function App({ title, children, className }) {
  const { t } = useTranslation();
  return (
    <main>
      <Navbar />
      <div
        className={`mt-40 mb-16 px-5 sm:px-10 md:px-20 max-w-6xl mx-auto w-fit ${className}`}
      >
        {children}
      </div>
      {(title === "home" || title === "recommendation") && (
        <>
          <section className="mb-16 sm:mb-24 md:mb-32 flex flex-col sm:flex-row gap-8 sm:gap-12 px-5 sm:px-10 md:px-20 max-w-6xl mx-auto">
            <div className="w-full sm:w-1/2">
              <span className="p-2 border-2 rounded-md border-orange-100 text-orange-400 text-xs text-nowrap w-fit h-fit">
                {t("FAQ")}
              </span>
              <h1 className="font-semibold text-primary-black leading-tight md:leading-snug lg:leading-normal text-2xl md:text-3xl lg:text-4xl mt-4 mb-8">
                {t("Frequently Asked Questions")}
              </h1>
              <p className="text-slate-500">
                {t(
                  "Here are some frequently asked questions about this platform"
                )}
              </p>
            </div>
            <div className="w-full sm:w-1/2">
              <Accordion className="border-none" collapseAll>
                <Accordion.Panel>
                  <Accordion.Title>
                    {t("How does this platform measure KPIs?")}
                  </Accordion.Title>
                  <Accordion.Content className="">
                    <p className="mb-2 text-slate-500 dark:text-gray-400">
                      {t(
                        "This platform uses calculation methods that have been proven effective in measuring KPIs"
                      )}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    {t("Can I customize the KPIs I want to measure?")}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-slate-500 dark:text-gray-400">
                      {t(
                        "Yes, you can adjust KPIs according to your business needs"
                      )}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    {t("What about my data security?")}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-slate-500 dark:text-gray-400">
                      {t(
                        "The security of your data is our priority. We use data encryption to protect your data"
                      )}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    {t("Can I customize the KPIs I want to measure?")}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-slate-500 dark:text-gray-400">
                      {t(
                        "Yes, you can adjust KPIs according to your business needs"
                      )}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    {t("How does this platform measure KPIs?")}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-slate-500 dark:text-gray-400">
                      {t(
                        "This platform uses calculation methods that have been proven effective in measuring KPIs"
                      )}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </div>
          </section>
          <Jumbotron
            className="border-2 border-primary-macaroni bg-cover bg-center mb-16 sm:mb-24 md:mb-32 rounded-[28px] mx-5 sm:mx-10 md:mx-28 w-full py-12"
            titleClassName="max-w-xl"
            style={{ backgroundImage: "url('/assets/img/modern-5.png')" }}
            title={t("Maximize KPI Potential with AI")}
            subtitle={t(
              "Have you seen your KPI potential with the simulator? It's time to upgrade your strategy! Come on, schedule a demo now and learn how our AI can help you achieve your business targets more efficiently."
            )}
            buttons={[
              {
                label: `${t("Schedule a Free Demo Now!")}`,
                href: "https://calendly.com/hulutalent-marketing/demo",
                target: "_blank",
                className:
                  "bg-primary-macaroni text-white hover:text-primary-macaroni hover:bg-[#181617] px-8 py-2 rounded-full",
              },
            ]}
          ></Jumbotron>
        </>
      )}

      <Footer />
      <ToastContainer />
    </main>
  );
}
