import React from "react";

export default function Jumbotron({
  title,
  subtitle,
  className,
  titleClassName,
  subtitleClassName,
  style = {},
  buttons = [],
  children,
}) {
  const defaultClasses =
    "px-4 max-w-screen-xl text-center rounded-xl";
  return (
    <main>
      <section className="bg-white dark:bg-gray-900 flex justify-center">
        <div className={`${defaultClasses} ${className}`} style={style}>
          <h1 className={`leading-tight md:leading-snug lg:leading-normal text-2xl md:text-3xl lg:text-4xl text-primary-black dark:text-white font-semibold mx-auto ${titleClassName}`}>
            {title}
          </h1>
          {subtitle && (
            <p className="mt-6 mb-8 text-sm font-normal text-slate-500 max-w-xl dark:text-slate-400 mx-auto">
              {subtitle}
            </p>
            )}
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 mb-4">
            {buttons.map((button, index) => (
              <a
                key={index}
                href={button.href || "#"}
                className={button.className}
                onClick={button.onClick}
                target={button.target}
                rel="noopener noreferrer"
              >
                {button.label}
                {button.icon && (
                  <button.icon className="w-5 h-5 ml-2" aria-hidden="true" />
                )}
              </a>
            ))}
          </div>
          {children && <div className="mt-8">{children}</div>}
        </div>
      </section>
    </main>
  );
}
