import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ top = 0, behavior = 'smooth' }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top,
      behavior
    });
  }, [pathname, top, behavior]);

  return null;
};

export default ScrollToTop;
