import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';
import LoadingState from './components/loadingState';
import NotFound from './pages/Default/NotFound';

function App() {
  const Home = lazy(() => import('./pages/home'));
  const Recomendation = lazy(() => import('./pages/home/recomendation'));
  const ManualBook = lazy(() => import('./pages/helpCenter/manualBook'));
  const ManualBookList = lazy(() => import('./pages/helpCenter/manualBook/list'));
  const ManualBookDetail = lazy(() => import('./pages/helpCenter/manualBook/detail'));
  const TutorialGuide = lazy(() => import('./pages/helpCenter/tutorialGuide'));
  const TutorialGuideList = lazy(() => import('./pages/helpCenter/tutorialGuide/list'));
  const TutorialGuideDetail = lazy(() => import('./pages/helpCenter/tutorialGuide/detail'));

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingState />}>
        <ScrollToTop behavior="instant" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recomendation" element={<Recomendation />} />
          <Route path="/help-center-manual-book" element={<ManualBook />} />
          <Route path="/help-center-manual-book/list" element={<ManualBookList />} />
          <Route path="/help-center-manual-book/detail/:slug" element={<ManualBookDetail />} />
          <Route path="/help-center-tutorial-guide" element={<TutorialGuide />} />
          <Route path="/help-center-tutorial-guide/list" element={<TutorialGuideList />} />
          <Route path="/help-center-tutorial-guide/detail/:slug" element={<TutorialGuideDetail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
