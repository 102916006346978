import React from "react";

const Footer = () => {
  return (
    <main>
      <footer className="footer px-5 sm:px-10 md:px-20 py-8 bg-[#FFF7ED]">
        <div className="max-w-6xl mx-auto">
          <div className="md:flex md:justify-between md:space-x-16">
            <div className="w-fit min-w-40">
              <a href="https://hulutarget.com" className="flex items-center mb-6 md:-translate-y-2">
              <img
              src="/assets/icon/hulutarget.png"
              className="h-14"
              alt="hulutarget Logo"
            />
            <img
              src="/assets/icon/hulu-target.png"
              className="h-10 -translate-x-5"
              alt="hulutarget Logo"
            />
              </a>
            </div>
            <div className="address">
              <div className="address-1 mb-6">
                <h2 className="mb-3 text-sm font-semibold text-gray-900 dark:text-white">
                  Jakarta
                </h2>
                <p className="text-xs text-slate-500 mb-0">
                  Office 8 Building, Jl. Senopati No.8, RT.8/RW.3, Senayan, Kec.
                  Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta
                  12190
                </p>
              </div>
              <div className="address-2 mb-6">
                <h2 className="mb-3 text-sm font-semibold text-gray-900 dark:text-white">
                  Banjarmasin
                </h2>
                <p className="text-xs text-slate-500 mb-0">
                  Jl. A. Yani No.KM 3,5, Kebun Bunga, Kec. Banjarmasin Tim., Kota
                  Banjarmasin, Kalimantan Selatan 70237
                </p>
              </div>
            </div>
            <div className="Features">
              <div>
                <h2 className="mb-3 text-sm font-semibold text-gray-900 dark:text-white">
                  Features
                </h2>
                <ul className="flex flex-col items-start text-xs text-slate-500">
                  <li className="mb-3">Key Performance Indicator</li>
                  <li className="mb-3">Key Performance Indicator Evaluator and Recommendation</li>
                  <li className="mb-3">Dashboard Analytics</li>
                  <li className="mb-3">OKR</li>
                  <li className="mb-3">Performance Review</li>
                  <li className="mb-3">Result</li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <h4 className="copyright text-xs text-slate-500">
              © 2024 PT. Cipta Daya Inovasi - All Rights Reserved
            </h4>
            <div className="social-icons flex space-x-4 mt-4 md:mt-0">
              <a href="/" className="footer-icon">
                <img src="/assets/icon/x.svg" alt="Twitter" className="w-4 h-4" />
              </a>
              <a href="/" className="footer-icon">
                <img
                  src="/assets/icon/facebook.svg"
                  alt="Facebook"
                  className="w-4 h-4"
                />
              </a>
              <a href="/" className="footer-icon">
                <img
                  src="/assets/icon/linkedin.svg"
                  alt="LinkedIn"
                  className="w-4 h-4"
                />
              </a>
              <a href="/" className="footer-icon">
                <img
                  src="/assets/icon/instagram.svg"
                  alt="Instagram"
                  className="w-4 h-4"
                />
              </a>
              <a href="/" className="footer-icon">
                <img
                  src="/assets/icon/youtube.svg"
                  alt="Instagram"
                  className="w-4 h-4"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Footer;
